@import "./node_modules/bootstrap/scss/bootstrap";

html,
body {
  height:100%;
  background-attachment: fixed;
  background-image: radial-gradient(circle at 40.79% -2.23%, #9abc73 0, #8fbf7a 3.57%, #83c081 7.14%, #77c28a 10.71%, #6ac393 14.29%, #5dc49c 17.86%, #4ec5a6 21.43%, #3ec6b0 25%, #2bc6ba 28.57%, #11c6c4 32.14%, #00c5cd 35.71%, #00c5d6 39.29%, #00c4df 42.86%, #00c3e6 46.43%, #0cc1ed 50%, #2dbff3 53.57%, #44bdf7 57.14%, #58bbfa 60.71%, #6bb8fc 64.29%, #7db5fc 67.86%, #8db1fc 71.43%, #9daef9 75%, #acaaf6 78.57%, #b9a7f1 82.14%, #c6a3ec 85.71%, #d1a0e5 89.29%, #db9ddd 92.86%, #e49ad5 96.43%, #eb97cc 100%);
  color:#fff;
  font-family: 'Roboto', sans-serif;
  margin:0;
  padding:0;
}

.logo {
  min-width: 100px;
  width: 15%;
  display: block;
  margin:20px 0 20px;
}
.info {
  .card-body {
    flex: none;
  }
  small {
    color: #999;
  }
}

.info,
.portfolio {
  .card {
    // box-shadow: inset 1px 1px rgb(255 255 255 / 20%), inset -1px -1px rgb(255 255 255 / 10%), 1px 3px 24px -1px rgb(0 0 0 / 15%);
    // background: transparent;
    // background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2) 70%) !important;
    // padding: 3px;
    // background-color: transparent;

      box-shadow: inset 1px 1px rgb(255 255 255 / 20%), inset -1px -1px rgb(255 255 255 / 10%), 1px 3px 24px -1px rgb(0 0 0 / 15%);
      background-color: transparent;
      background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4) 70%);
      backdrop-filter: blur(5px);
      border: none;

    ul {
      padding: 5px 0;
      li {
        background-color: transparent;
      }
    }
    border: none;
  }
}


.info .card-container,
.portfolio .card {
  width:100%;
  margin-bottom: 2em;
  

  @include media-breakpoint-only(md) {
    width:48%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    .card {
      flex:1;
    }
    // &:nth-child(2n) {
    //   // margin-right: 0;
    // }
  }
  @include media-breakpoint-up(lg) {
    width:31%;
    margin-right: 2%;
    
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

a {
  color:#000;
}
h5 {
  font-weight: 900;
  small {
    font-weight: 700;
    color:rgba(0,0,0,.5);
    padding-left: .33em;
    font-size: .75em;
  }
}
h6 {
  font-weight: 700;
  margin:0;
}
h1 {
  font-weight: 900;
  text-transform: uppercase;
}
h2.card-title {
  font-weight: 900;
  font-size: 1.5em;;
  text-transform: uppercase;
  margin: 0;
}
// html,
// body {
//   height:100%;
//   background-attachment: fixed;
//   background-image: radial-gradient(circle at 40.79% -2.23%, #9abc73 0, #8fbf7a 3.57%, #83c081 7.14%, #77c28a 10.71%, #6ac393 14.29%, #5dc49c 17.86%, #4ec5a6 21.43%, #3ec6b0 25%, #2bc6ba 28.57%, #11c6c4 32.14%, #00c5cd 35.71%, #00c5d6 39.29%, #00c4df 42.86%, #00c3e6 46.43%, #0cc1ed 50%, #2dbff3 53.57%, #44bdf7 57.14%, #58bbfa 60.71%, #6bb8fc 64.29%, #7db5fc 67.86%, #8db1fc 71.43%, #9daef9 75%, #acaaf6 78.57%, #b9a7f1 82.14%, #c6a3ec 85.71%, #d1a0e5 89.29%, #db9ddd 92.86%, #e49ad5 96.43%, #eb97cc 100%);
//   color:#fff;
//   font-family: 'Roboto', sans-serif;
//   margin:0;
//   padding:0;
// }
// main {
//   /* display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   width: 100%; */
//   /* margin:15px; */
//   padding: 15px;
//   max-width: 400px;
//   margin: 0 auto;
// }
// a {
//   color:#fff;
// }
// .logo {
//   max-width: 600px;
//   width: 80%;
//   display: block;
//   margin:10px auto 20px;
// }
// h1 {
//   font-family: 'Bungee', cursive;
//   font-weight: 400;
//   font-size: 10vw;
//   text-shadow: 5px 5px 2px rgba(0,0,0,.1);
// }
// h2 {
//   font-family: 'Bungee', cursive;
//   margin:0 0 .25em;
//   color:#000;
//   font-size: 2.5em;
// }
// img {
//   max-width: 100%;
//   display: block;
// }
// .contact,
// .education,
// .work {
//   box-shadow: inset 1px 1px rgb(255 255 255 / 20%), inset -1px -1px rgb(255 255 255 / 10%), 1px 3px 24px -1px rgb(0 0 0 / 15%);
//   background-color: transparent;
//   background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2) 70%);
//   backdrop-filter: blur(5px);
//   padding:20px 20px 10px 20px;
//   border-radius: 15px;
//   margin:0 0 1em;
// }
// p {
//   margin: 0 0 1em;
// }
// ul {
//   list-style: none outside;
//   padding: 0;
//   margin:0;
// }
// ul li {
//   margin:0 0 1em;
// }
// h3 {
//   margin:0 0 .2em;
// }
// /* .contact ul {
//   display: flex;
// } */
// .list li {
//   padding:0 0  10px;
//   border-bottom: 2px inset rgba(255,255,255,.3);
// }
// .list li:last-child {
//   border-bottom: none;
// }